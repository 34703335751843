import logo from './logo.png';
import './App.css';

function App() {
const divStyle = {
    fontSize: '18px',
};
  return (
      <div>
        <div className="nav-container">
            <a id="top"></a>
            <nav className="pb16 pb-xs-0">
                <div className="nav-utility bg-primary">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4  text-center-xs">
                                <span className="sub">2961 Beachwood Dr,Merced,California,95348</span>
                            </div>
                            <div className="col-sm-4 text-center">
                                <ul className="list-inline social-list">
                                    <li>
                                        <a href="https://www.facebook.com/Yummy-Thai-100987675414175/">
                                            <i className="ti-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/mercedyummythai/">
                                            <i className="ti-instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm-4 text-right text-center-xs">
                                <span className="sub">Open Wed-Mon: 10am - 8pm(Summer 9pm)</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt120 pb80 pt-xs-40 pb-xs-16">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <a href="index.html">
                                    <img alt="Logo" className="logo" src={logo} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="module widget-handle mobile-toggle text-center visible-xs">
                    <i className="ti-menu"></i>
                </div> */}
                <div className="nav-bar text-center">
                    <div className="container">
                        <div className="row">
                            <div className="text-center">
                                <ul className="menu inline-block mb-xs-16">
                                    <li className="top-link hidden-xs">
                                        <a href="#top" className="inner-link">
                                            <i className="ti-arrow-up"></i> Top</a>
                                    </li>
                                    <li>
                                        <a href="#about" className="inner-link" style={divStyle}>About</a>
                                    </li>
                                    <li>
                                        <a href="#menu" className="inner-link" style={divStyle}>Menu</a>
                                    </li>
                                    <li>
                                        <a href="#map" className="inner-link" style={divStyle}>Map</a>
                                    </li>
                                    <li>
                                        <a href="#contact" className="inner-link" style={divStyle}>Contact</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            
        </div>
        
        <div className="main-container">
            <section className="pb0 pt20">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <div className="image-slider slider-arrow-controls mb0">
                                <ul className="slides">
                                    <li>
                                        <img alt="Pic" src="img/home1.jpg" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <a id="about"></a>
            <section className="pb0">
                <div className="container">
                    <div className="row  mb64 mb-xs-40">
                        <div className="col-sm-12 text-center">
                            <div className="ribbon">
                                <h6 className="uppercase mb0">Yummy Thai</h6>
                            </div>
                        </div>
                    </div>
                    <div className="row mb64 mb-xs-40">
                        <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 text-center">
                            <h3>
                                Yummy Thai and Laos Food.
                            </h3>
                            <h3>
                                Available now in Merced.
                            </h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb0">
                <div className="container">
                    <div className="row">
                    <div className="col-sm-6 text-center">
                            <div className="image-slider slider-arrow-controls mb0">
                                <ul className="slides">
                                    <li>
                                        <img alt="Pic" src="img/body1.jpg" />
                                    </li>
                                </ul>
                            </div>
                    </div>
                    <div className="col-sm-6 text-center">
                            <div className="image-slider slider-arrow-controls mb0">
                                <ul className="slides">
                                    <li>
                                        <img alt="Pic" src="img/body2.jpg" />
                                    </li>
                                </ul>
                            </div>
                    </div>

                        
                    </div>
                </div>
            </section>
            <a id="menu"></a>
            <section className="pb0">
                <div className="container">
                    <div className="row  mb64 mb-xs-40">
                        <div className="col-sm-12 text-center">
                            <div className="ribbon">
                                <h6 className="uppercase mb0">Menu</h6>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row">
                                <div className="menu center">
                                    <a href="https://yummythaimercedca.smiledining.com//?2022101701" target="_blank">
                                        <img src="img/restaurant-menu.png" alt="menu1"/>
                              
                                    </a>
                                </div>

                            
                        </div>
                    </div>
                </div>
            </section>

            <a id="map"></a>
            <section className="pb0" >
                <div className="container">
                    <div className="row  mb64 mb-xs-40">
                        <div className="col-sm-12 text-center">
                            <div className="ribbon">
                                <h6 className="uppercase mb0">@mercedyummythai</h6>
                            </div>
                        </div>
                    </div>
                    <div className="map-holder pt120 pb120">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.4853135870376!2d-120.5304368848665!3d37.33101757984269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80916918a7d3f22f%3A0x6956dfa333103f3d!2sYummy%20Thai!5e0!3m2!1sen!2sus!4v1668492669817!5m2!1sen!2sus" width="600" height="450"  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                
            </div>
            </div>
            </section>
            <a id="contact"></a>
            <section>
                <div className="container">
                    <div className="row  mb64 mb-xs-40">
                        <div className="col-sm-12 text-center">
                            <div className="ribbon">
                                <h6 className="uppercase mb0">Contact</h6>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="map-holder pt120 pb120">
                                <div className="map-canvas" data-latlong="" data-address="orchard street, armadale" data-maps-api-key="AIzaSyAVsRr4i3ovR45biSx0DoWRswL1kfdO9ZU"></div>
                                <div className="col-md-6 col-md-offset-3 col-sm-10 col-sm-offset-1 contact-details">
                                    <div className="pt80 pb56 bg-white p32 overflow-hidden">
                                        <div className="col-sm-10 col-sm-offset-1">
                                            <p>
                                                <strong>Open Wed-Mon: 10am - 8pm(Summer 9pm)</strong>
                                                <br /> Drop by to eat yummy thai food,
                                                <br /> for larger groups call ahead to
                                                <br /> make a reservation.
                                            </p>
                                            <p>
                                                2961 Beachwood Dr,Merced
                                                <br /> California,95348
                                            </p>
                                            <p>
                                                Phone: (209) 230-0130

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="bg-primary pt120 pb120 pt-xs-40 pb-xs-64">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 mb-xs-24 text-center-xs">
                            <div className="inline-block display-block-xs text-center-xs">
                                <h6 className="uppercase mb16">Merced Yummy Thai.</h6>
                                <span className="sub display-block">2961 Beachwood Dr,Merced,California,95348</span>
                                <span className="sub display-block">Open Wed-Mon: 10am - 8pm(Summer 9pm)</span>
                            </div>
                        </div>
                        <div className="col-md-3 col-md-offset-3 col-sm-4 col-sm-offset-2 text-center">
                            <h6 className="uppercase">Follow Us</h6>
                            <ul className="list-inline social-list">
                                <li>
                                    <a href="https://www.facebook.com/Yummy-Thai-100987675414175/" target="_blank">
                                        <i className="ti-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/mercedyummythai/" target="_blank">
                                        <i className="ti-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        </div>
    
  );
}

export default App;
